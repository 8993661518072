<template>
	<div>
		<AlertSeasonEncaste />
		<div class="d-flex flex-column flex-xl-row align-items-end align-items-xl-center justify-content-between">
			<b-card no-body class="d-flex w-100 p-1">
				<b-nav pills class="container-items-encaste">
					<template v-for="link in navLinks">
						<b-nav-item
							:to="{ name: link.path }"
							:key="link.id"
							tag="li"
							active-class="nav-link active active-item-nav"
							link-classes="active-item-nav"
							@click="getLink(link)"
							v-if="
								(link.requiresAdmin == isAdmin ||
									link.requiresEncastador == isEncastador ||
									link.requiresInvitado == isInvitado) &&
								link.path != 'posturas-sr'
							"
						>
							<template v-if="link.title == 'Lotes' && counterStages > 0 && isEncastador">
								<span>{{ link.title }}</span>
								<b-badge pill variant="danger" class="ml-1">
									{{ counterStages }}
								</b-badge>
							</template>
							<template v-else>
								{{ link.title }}
							</template>
						</b-nav-item>
						<b-nav-item
							v-else
							:to="{ name: link.path }"
							tag="li"
							active-class="nav-link active active-item-nav"
							link-classes="active-item-nav"
							@click="getLink(link)"
							:active="
								[
									'posturas-sr-pendientes',
									'posturas-sr-activas',
									'posturas-sr-por finalizar',
									'posturas-sr-finalizadas',
									'posturas-sr-anuladas',
									'padrillos-con-posturas-sr',
									'padrillos-sin-posturas-sr',
									'madrillas-con-posturas-sr',
									'madrillas-sin-posturas-sr',
									'insemination-sr-pendientes',
									'insemination-sr-realizadas',
								].includes($route.name)
							"
						>
							<template v-if="link.title == 'Lotes' && counterStages > 0 && isEncastador">
								<span>{{ link.title }}</span>
								<b-badge pill variant="danger" class="ml-1">
									{{ counterStages }}
								</b-badge>
							</template>
							<template v-else>
								{{ link.title }}
							</template>
						</b-nav-item>
					</template>
				</b-nav>
			</b-card>

			<!-- <b-card
				class="d-flex p-1 ml-lg-1"
				:class="$store.getters['app/currentBreakPoint'] === 'xs' ? 'w-100' : ''"
				no-body
				v-if="(isAdmin || isSupervisor) && $route.path.includes('posturas')"
				:style="{ width: '220px' }"
			>
				<b-button
					:disabled="disabledEncaste"
					class="mb-0"
					variant="primary"
					@click="controllerAddPostura = true"
					block
				>
					<span class="text-nowrap"> Agregar Postura </span>
				</b-button>
			</b-card> -->
		</div>

		<AddPostura v-if="controllerAddPostura" @hidden="closeAddPostura" />

		<!-- {{ $store.state.utilities.seasons.length }} -->

		<router-view v-if="$store.state.utilities.seasons.length > 0"></router-view>
	</div>
</template>

<script>
import AlertSeasonEncaste from "./posturas/AlertSeasonEncaste.vue";
import AddPostura from "./posturas/modals/AddPostura.vue";
export default {
	name: "EncasteMain",
	components: {
		AddPostura,
		AlertSeasonEncaste,
	},
	async created() {
		// this.isPreloading();
		if (this.isEncastador) {
			await this.getStagesPending();
		}
	},
	data() {
		return {
			navLinks: [
				{
					title: "Dashboard",
					requiresAdmin: true,
					requiresEncastador: true,
					requiresInvitado: true,
					path: "dashboard-encaste-sr",
				},
				{
					title: "Registro de huevos",
					requiresAdmin: true,
					requiresEncastador: true,
					requiresInvitado: true,
					path: "regsiter-eggs",
				},
				{
					title: "Posturas",
					requiresAdmin: true,
					requiresEncastador: true,
					requiresInvitado: true,
					path: "posturas-sr",
				},
				{
					title: "Lotes",
					requiresAdmin: true,
					requiresEncastador: true,
					requiresInvitado: true,
					path: "lotes-sr",
				},
				{
					title: "Pollos",
					requiresAdmin: true,
					requiresEncastador: true,
					requiresInvitado: true,
					path: "pollos-sr",
				},
				{
					title: "Corrales",
					requiresAdmin: true,
					requiresEncastador: true,
					requiresInvitado: true,
					path: "corrales-sr",
				},
				// {
				// 	title: "Inseminacion",
				// 	requiresAdmin: true,
				// 	requiresEncastador: true,
				// 	requiresInvitado: true,
				// 	path: "insemination-sr-pendientes",
				// },

				//
				//corrales-sr
				// TODO: defiinir donde va a estar migracion
				// {
				//   title: "Migración",
				//   requiresAdmin: true,
				//   requiresEncastador: false,
				//   requiresInvitado: true,
				//   path: "migracion-sr",
				// },
			],
			selectedLink: null,
			counterStages: null,
			controllerAddPostura: false,
		};
	},
	computed: {},
	methods: {
		getLink(link) {
			this.selectedLink = link;
		},
		closeAddPostura() {
			this.controllerAddPostura = false;
		},
		async getStagesPending() {
			try {
				const { data } = await axios.get("api/lote/count-stages-pending");
				this.counterStages = data.stages_pendings;
			} catch (err) {
				console.error(err);
			}
		},
	},
};
</script>

<style scoped lang="scss">
.notification-icon-link {
	background: #f00;
	padding: 2px 6px 2px 6px;
	border-radius: 50%;
	color: #fff;
	vertical-align: top;
}
.container-items-encaste {
	display: flex !important;
	flex-direction: column !important;
	margin-bottom: 0;
	transition: 1s all ease-in-out;
	@media screen and (min-width: 700px) {
		flex-direction: row !important;
	}
}
</style>
