var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('AlertSeasonEncaste'),_c('div',{staticClass:"d-flex flex-column flex-xl-row align-items-end align-items-xl-center justify-content-between"},[_c('b-card',{staticClass:"d-flex w-100 p-1",attrs:{"no-body":""}},[_c('b-nav',{staticClass:"container-items-encaste",attrs:{"pills":""}},[_vm._l((_vm.navLinks),function(link){return [(
							(link.requiresAdmin == _vm.isAdmin ||
								link.requiresEncastador == _vm.isEncastador ||
								link.requiresInvitado == _vm.isInvitado) &&
							link.path != 'posturas-sr'
						)?_c('b-nav-item',{key:link.id,attrs:{"to":{ name: link.path },"tag":"li","active-class":"nav-link active active-item-nav","link-classes":"active-item-nav"},on:{"click":function($event){return _vm.getLink(link)}}},[(link.title == 'Lotes' && _vm.counterStages > 0 && _vm.isEncastador)?[_c('span',[_vm._v(_vm._s(link.title))]),_c('b-badge',{staticClass:"ml-1",attrs:{"pill":"","variant":"danger"}},[_vm._v(" "+_vm._s(_vm.counterStages)+" ")])]:[_vm._v(" "+_vm._s(link.title)+" ")]],2):_c('b-nav-item',{attrs:{"to":{ name: link.path },"tag":"li","active-class":"nav-link active active-item-nav","link-classes":"active-item-nav","active":[
								'posturas-sr-pendientes',
								'posturas-sr-activas',
								'posturas-sr-por finalizar',
								'posturas-sr-finalizadas',
								'posturas-sr-anuladas',
								'padrillos-con-posturas-sr',
								'padrillos-sin-posturas-sr',
								'madrillas-con-posturas-sr',
								'madrillas-sin-posturas-sr',
								'insemination-sr-pendientes',
								'insemination-sr-realizadas' ].includes(_vm.$route.name)},on:{"click":function($event){return _vm.getLink(link)}}},[(link.title == 'Lotes' && _vm.counterStages > 0 && _vm.isEncastador)?[_c('span',[_vm._v(_vm._s(link.title))]),_c('b-badge',{staticClass:"ml-1",attrs:{"pill":"","variant":"danger"}},[_vm._v(" "+_vm._s(_vm.counterStages)+" ")])]:[_vm._v(" "+_vm._s(link.title)+" ")]],2)]})],2)],1)],1),(_vm.controllerAddPostura)?_c('AddPostura',{on:{"hidden":_vm.closeAddPostura}}):_vm._e(),(_vm.$store.state.utilities.seasons.length > 0)?_c('router-view'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }